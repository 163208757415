import React from "react"
import {Maps} from "../components/Map";
import {Header} from "../components/Header";
import {IPage} from "../types";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import Situatie from "../components/Situatie";
import {ChildImageSharp} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {Col, Container, Row} from "react-bootstrap";
import {graphql} from "gatsby";
import {Project} from "../../../gatsby-theme-nieuwbouw/src/graphqlTypes";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header image={data.desktopImage.childImageSharp.gatsbyImageData} />
            <Container fluid>
                <div
                    style={{
                        position: 'relative',
                        zIndex: 21,
                    }}
                    className={"bg-white mt-n5 py-3 text-center"}
                >
                    <h2
                        className={'pt-5 pb-3 px-4 d-inline-block bg-white'}
                        style={{
                            position: 'relative',
                            zIndex: 23,
                        }}
                    >
                        Selecteer je eigen <span className={'bg-primary'}>woonvrijheid</span>
                    </h2>
                    <div
                        className={'mt-n5'}
                        style={{
                            position: 'relative',
                            zIndex: 22,
                        }}
                    >
                        <Maps />
                    </div>
                </div>
                <div style={{maxWidth: 707}} className={'mx-auto text-center my-5'}>
                    <p className={'lead font-weight-bold text-uppercase'}>
                        Wonen met een view. KADE 23 is een ideale woonplek met veel mogelijkheden om eigen woonaccenten aan te brengen. Een verrassende woonmix van 23 ééngezinswoningen met een divers karakter in kleuren en lagen. Ondanks de diversiteit in aangezicht, heeft het alle ingrediënten in zich om een hechte buurt te worden, waar echte vriendschappen ontstaan. Hier vorm je samen de buurt.
                    </p>
                </div>
                <div className={'my-5'}>
                    <Situatie />
                </div>
            </Container>
            <Container
                fluid={'lg'}
                as={'section'}
                className={"my-5 text-center text-md-left"}
            >
                <Row as={'section'} className={'my-5 mb-md-0'}>
                    <Col md={7}>
                        <div
                            className={'content content-intro content-left'}
                        >
                            <h2>
                                De Oostergracht als <span className={'bg-primary'}> inspiratie</span>
                            </h2>
                            <p>
                                De 23 kadewoningen bieden aan de voorkant uitzicht op de gracht en het groen. Wat de architectuur extra bijzonder maakt, is dat de woningen in een lichte boog geplaatst worden. Ze vormen als het ware mee met de Oostergracht. Dit geeft de woningen een speels effect mee. Aan de voorkant zijn ze dan ook iets breder dan aan de achterkant.
                            </p>
                            <p>
                                Wonen in KADE 23 betekent automatisch ook duurzaam wonen, voorzien van standaard duurzame toepassingen van nu. Zo hebben alle woningen standaard een EPC van 0.4 en worden ze verwarmd door middel van een luchtwarmtepomp.
                            </p>
                        </div>
                    </Col>
                    <Col md={5}>
                        <StaticImage
                            alt={""}
                            aspectRatio={474/367}
                            src={"../img/sfeer/kloosterveste.jpg"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'align-items-center my-5'}>
                    <Col md={{ span: 5, order: 1 }}>
                        <div
                            className={'content content-right'}
                        >
                            <h2>
                                Laat je <span className={'bg-primary'}>hart </span> spreken
                            </h2>
                            <p>
                                In je achterhoofd weet je dat jullie als gezin de juiste keuze gaan maken. Logisch, want je laat je hart spreken.
                            </p>
                            <p>
                                Mede ingegeven door het fraaie straatbeeld, de woon-mogelijkheden, de uniekheid van de woonomgeving en het duurzame karakter ervan. Door gelijkgestemde medebuurtbewoners, maar vooral ook door de voorzieningen en de ruimte om je heen. Proef het woongenot van Kade 23.
                            </p>
                        </div>
                    </Col>
                    <Col md={7}>
                        <StaticImage
                            alt={""}
                            aspectRatio={670/327}
                            src={"../img/sfeer/nieuw beeld bij laat je hart spreken.jpg"} />
                    </Col>
                </Row>
                {data.project.hoofdfoto &&
                <GatsbyImage
                    alt={"interieur"}
                    image={data.project.hoofdfoto.localFile.childImageSharp.gatsbyImageData} />
                }
            </Container>
        </Page>

    );
}

interface PageQueryData {
    markdownRemark: IPage
    desktopImage: ChildImageSharp
    project: Project
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "situatie"}}) {
            ...Page
        }
        desktopImage: file(relativePath: {eq: "sfeer/nieuw beeld pagina locatie en hoofdbeeld situatie en extra.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 4
                    transformOptions: {cropFocus: ENTROPY}
                    layout: FULL_WIDTH
                )
            }
        }
        project {
            hoofdfoto {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`

