import React from 'react'
import {GoogleMap, LoadScript, Marker, Polygon} from "@react-google-maps/api"
import {graphql, useStaticQuery} from "gatsby";

const POSITION = {
    lat: 53.000382,
    lng: 6.515816,
}
export const Maps = () => {

    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: {eq: "logo.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 20, quality: 100, layout: FIXED)
                }
            }
        }
    `)
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAG1pfezyKDy1DuiStaBtiZVRa9YqxugPs"
        >
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '600px'
                }}
                center={POSITION}
                zoom={16}
            >
                <Marker
                    position={POSITION}
                    icon={data.logo.childImageSharp.gatsbyImageData.src}
                />
            </GoogleMap>
        </LoadScript>
    );
}